/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-09-05 11:18:10
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/router/home-router.js
 */
import stageConfig from '@/config/stage' // 引入舞台配置

/**
 * 深度遍历配置树, 摘取叶子节点作为路由部分
 * @param {*} config 配置项
 * @param {*} fuc 回调函数
 */
function deepTravel(config, fuc) {
  if (Array.isArray(config)) {
    config.forEach(subConfig => {
      deepTravel(subConfig, fuc)
    })
  } else if (config.children?.length) {
    if (config.type === 'view') {
      fuc(config)
    }
    config.children.forEach(subConfig => {
      deepTravel(subConfig, fuc)
    })
  } else {
    fuc(config)
  }
}

const homeRouter = []

/**
 * 构造舞台view路由
 */
deepTravel(stageConfig, viewConfig => {
  const viewRouter = {}
  viewRouter.path = viewConfig.route
  viewRouter.name = viewConfig.name
  viewRouter.component = () => import(`@/${viewConfig.filePath}`)
  viewRouter.meta = {
    keepAlive: (viewConfig.meta && viewConfig.meta.keepAlive) || false,
    title: viewConfig.title,
    icon: viewConfig.icon,
    permission: viewConfig.permission,
    type: viewConfig.type,
    blueBaseColor: viewConfig.blueBaseColor ? 'viewConfig.blueBaseColor' : '',
  }
  homeRouter.push(viewRouter)
})

export default homeRouter
