/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-11-21 14:54:16
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '结算管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/finance/',
  route: '/settlement-manager',
  order: null,
  inNav: true,
  children: [

    {
      title: '消费流水结算明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/settlement-manager/consumptionSettlementAccountDetails',
      filePath: 'plugin/settlement-manager/view/report_module/consumptionSettlementAccountDetails.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '储值流水结算明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/settlement-manager/storedSettlementAccountDetails',
      filePath: 'plugin/settlement-manager/view/report_module/storedSettlementAccountDetails.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '结算统计',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/settlement-manager/settlementStatistics',
      filePath: 'plugin/settlement-manager/view/report_module/settlementStatistics.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
  ],
}

export default linCmsUiRouter
