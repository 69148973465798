/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2024-07-16 16:24:21
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/cms-manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: 'CMS管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/operation/',
  route: '/cmsManager',
  order: null,
  inNav: true,
  children: [
    {
      title: '封面管理',
      type: 'view',
      name: 'coverManager',
      route: '/cmsManager/coverManager',
      filePath: 'plugin/cms-manager/view/cover-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    {
      title: '歌曲管理',
      type: 'view',
      name: 'songManager',
      route: '/cmsManager/songManager',
      filePath: 'plugin/cms-manager/view/song-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    {
      title: '库文件上传',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/cmsManager/uploadLibraryFiles',
      filePath: 'plugin/cms-manager/view/uploadLibraryFiles/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '链接管理',
      type: 'view',
      name: 'shortLinkManager',
      route: '/cmsManager/shortLinkManager',
      filePath: 'plugin/cms-manager/view/shortLinkManager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    {
      title: '模式资源管理',
      type: 'view',
      name: 'resourceManagement',
      route: '/cmsManager/resourceManagement',
      filePath: 'plugin/cms-manager/view/resourceManagement/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    // {
    //   title: '营销视频',
    //   type: 'view',
    //   name: 'marketingVideo',
    //   route: '/cmsManager/marketingVideo',
    //   filePath: 'plugin/cms-manager/view/marketingVideo/index.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    // },
  ],
}

export default linCmsUiRouter
